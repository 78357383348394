import React from 'react'
import Helmet from 'react-helmet'
import Footer from './Footer'
import Header from './Header'
import "../styles/index.scss"

const TemplateWrapper = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />

        <meta name="theme-color" content="#1a1a1a" />
        <meta charSet="utf-8" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="M K Catering" />
        <meta property="og:url" content="https://www.mkcateringuk.co.uk/" />
        <meta property="og:image" content="/img/og-image.jpg" />       
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default TemplateWrapper
