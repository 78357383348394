import React from 'react'
import { Link } from 'gatsby'
// import FacebookLogo from '../img/FacebookLogo/index'
// import ShareIcon from '../img/ShareIcon'


let copyrightDate = new Date();

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">

          <ul role="navigation"
            aria-label="main-navigation">
            <li>
              <Link activeClassName="active" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/menus">
                All Menus
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/contact">
                Contact
              </Link>
            </li>
          </ul> 
            
          {/* <div className="footer__share">
              <div className="footer__share-fb">
                <FacebookLogo />
              </div>   
              <div className="footer__share-share">
                <h3>Share</h3>
                <ShareIcon />
              </div>
          </div> */}

        </div>
        
        <p>
          © Copyright MK Catering {copyrightDate.getFullYear()}
          <span>Site by <a href="https://www.jamiekdonnelly.co.uk" target="_blank" rel="noopener noreferrer">Jamie K Donnelly</a></span>
        </p>   
        
      </footer>
    )
  }
}

export default Footer
