import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class MenuNav extends React.Component {
  render() {
    const { data } = this.props
    const { edges: menus } = data.allMarkdownRemark
    return (
      <ul className="menusNav">
        {menus && menus.map(({ node: menu }) => (                   
          <li key={menu.id}>
              <Link activeClassName="active" to={menu.fields.slug}>
                {menu.frontmatter.heading}
              </Link>
          </li>
        ))}
      </ul>
    )
  }
}

MenuNav.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query MenuNavQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "menu-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                heading
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <MenuNav data={data} count={count} />}
  />
)