import React from 'react'

const Logo = () => (
    <svg version="1.1" id="Logo"  x="0px" y="0px" viewBox="0 0 300 300">
   <g>
       <g>
           <path className="crest crest__clean" d="M210.176,275.043c-4.468,2.592-9.967,4.771-15.765,6.473
            c-5.81,1.685-11.907,2.884-17.635,3.82c-9.112,1.493-18.77,2.184-28.65,1.784c-9.876-0.39-19.969-1.864-29.856-4.521
            c-9.878-2.663-19.582-6.44-28.561-11.42c-8.995-4.94-17.331-10.926-24.66-17.644c-5.414-4.628-10.256-9.532-14.706-14.754
            c-4.398-5.266-8.362-10.871-12.017-16.93c-3.676-6.049-6.869-12.651-9.726-19.883c-2.834-7.241-5.259-15.165-6.996-23.974
            l-0.534-2.854c-0.171-0.952-0.387-1.896-0.499-2.855c-0.273-1.912-0.546-3.821-0.818-5.726c-0.188-1.928-0.375-3.852-0.562-5.771
            l-0.138-1.44l-0.066-1.414c-0.045-0.942-0.089-1.884-0.134-2.823l-0.067-1.408l-0.033-0.703l-0.017-0.352l-0.009-0.176
            l-0.002-0.139v-0.089c0.003-0.952,0.007-1.902,0.01-2.85c0.004-0.949,0.009-1.896,0.013-2.843
            c0.007-0.473-0.004-0.946,0.018-1.418l0.08-1.413c0.113-1.882,0.206-3.761,0.348-5.629c0.216-1.861,0.43-3.715,0.644-5.561
            c2.229-15.943,7.18-31.295,14.585-45.256C41.86,73.334,51.63,60.76,63.226,50.117C74.856,39.51,88.338,30.9,103.021,24.73
            c7.336-3.102,15.003-5.519,22.869-7.26c1.952-0.504,3.949-0.806,5.935-1.184c0.995-0.176,1.988-0.375,2.988-0.533l3.014-0.396
            l3.021-0.396c1.003-0.144,2.044-0.183,3.067-0.278l3.085-0.246l1.545-0.121l1.497-0.046c1.634-0.046,3.299-0.093,4.992-0.141
            l0.318-0.009l0.159-0.004l0.248,0.002l0.654,0.014c0.438,0.01,0.877,0.02,1.317,0.03c0.882,0.021,1.77,0.042,2.664,0.063
            c1.789,0.007,3.596,0.172,5.424,0.302c0.914,0.068,1.833,0.136,2.756,0.204c0.922,0.078,1.843,0.215,2.771,0.322
            c8.539,0.978,17.869,2.854,26.779,5.704c8.917,2.821,17.389,6.605,24.391,10.647c7.717,4.411,13.745,9.385,17.776,13.507
            c1.005,1.041,1.895,2.008,2.661,2.892c0.727,0.909,1.331,1.741,1.821,2.478c0.977,1.474,1.472,2.576,1.412,3.215
            c-1.323-0.78-2.821-1.766-4.47-2.93c-1.656-1.147-3.502-2.326-5.443-3.71c-4.031-2.607-8.628-5.727-13.928-8.611
            c-5.271-2.917-11.088-5.866-17.346-8.311c-6.247-2.46-12.867-4.534-19.549-6.04c-1.41-0.295-2.837-0.595-4.28-0.897
            c-1.447-0.279-2.925-0.483-4.409-0.733c-0.743-0.12-1.49-0.24-2.24-0.361c-0.756-0.086-1.515-0.172-2.277-0.258
            c-1.526-0.158-3.061-0.383-4.618-0.494c-1.559-0.104-3.129-0.208-4.712-0.313c-0.791-0.069-1.587-0.075-2.386-0.089
            c-0.799-0.015-1.601-0.03-2.405-0.045c-0.804-0.016-1.61-0.032-2.42-0.048c-0.801,0.022-1.604,0.045-2.41,0.068
            c-1.658,0.06-3.361,0.074-5.009,0.171c-6.458,0.4-13.177,1.308-19.8,2.666c-6.629,1.403-13.185,3.39-19.566,5.788
            c-6.397,2.373-12.526,5.392-18.369,8.705c-0.728,0.419-1.455,0.838-2.181,1.257c-0.361,0.211-0.729,0.412-1.084,0.632
            l-1.054,0.678c-1.404,0.902-2.803,1.801-4.195,2.696c-1.348,0.96-2.689,1.917-4.024,2.868l-1,0.711
            c-0.329,0.243-0.644,0.502-0.965,0.752c-0.64,0.504-1.278,1.007-1.915,1.508s-1.271,1-1.904,1.498
            c-0.63,0.5-1.22,1.046-1.829,1.564c-1.201,1.056-2.424,2.071-3.583,3.138c-1.14,1.088-2.271,2.168-3.393,3.239l-0.839,0.802
            l-0.796,0.837c-0.53,0.557-1.058,1.11-1.583,1.662c-2.151,2.16-4.029,4.489-5.96,6.681c-0.904,1.147-1.798,2.281-2.681,3.401
            c-0.44,0.561-0.878,1.118-1.313,1.671c-0.408,0.574-0.813,1.144-1.216,1.709c-10.007,13.812-17.291,29.715-21.225,46.289
            c-1.978,8.285-3.136,16.729-3.504,25.148l-0.083,3.157l-0.043,1.576l-0.021,0.787l0.015,0.719
            c0.053,2.149,0.107,4.294,0.161,6.434c0.15,2.058,0.301,4.109,0.451,6.156c0.25,2.063,0.499,4.122,0.747,6.174
            c2.405,16.802,8.141,32.887,16.538,47.213c8.389,14.341,19.403,26.931,32.174,37.142c2.29,1.914,4.73,3.608,7.114,5.366
            c1.205,0.859,2.459,1.643,3.683,2.468l1.844,1.224c0.619,0.399,1.261,0.76,1.89,1.143c1.263,0.756,2.524,1.511,3.784,2.264
            c1.294,0.693,2.586,1.386,3.876,2.077c0.647,0.343,1.287,0.699,1.938,1.032l1.98,0.94c1.322,0.621,2.632,1.269,3.96,1.87
            c1.346,0.562,2.69,1.122,4.034,1.683c0.674,0.272,1.337,0.578,2.021,0.824l2.052,0.738c1.37,0.483,2.729,1.004,4.107,1.458
            c1.389,0.422,2.777,0.844,4.164,1.266c0.696,0.202,1.383,0.439,2.086,0.613l2.108,0.524c1.407,0.343,2.809,0.714,4.22,1.032
            l4.261,0.825c0.711,0.134,1.419,0.281,2.132,0.402l2.148,0.3c1.53,0.207,3.058,0.438,4.59,0.627l4.612,0.409
            c0.769,0.059,1.536,0.157,2.307,0.185l2.312,0.088c1.534,0.051,3.098,0.139,4.609,0.161c1.557-0.005,3.132,0.021,4.652-0.037
            l2.299-0.065l2.31-0.12c1.54-0.065,3.073-0.225,4.61-0.334c1.531-0.174,3.069-0.291,4.595-0.51
            C186.773,283.087,198.778,279.821,210.176,275.043z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M79.74,155.528v-9.772c0-0.728-0.027-1.319-0.082-1.774c-0.055-0.455-0.183-0.8-0.382-1.037
                    c-0.2-0.236-0.473-0.4-0.819-0.491c-0.346-0.091-0.792-0.137-1.337-0.137c-0.583,0-1.047,0.046-1.392,0.137
                    c-0.347,0.091-0.62,0.264-0.819,0.519c-0.201,0.255-0.328,0.61-0.382,1.064c-0.055,0.456-0.082,1.029-0.082,1.72v26.042
                    c0,0.764,0.027,1.374,0.082,1.829s0.182,0.81,0.382,1.064c0.199,0.255,0.472,0.428,0.819,0.519
                    c0.345,0.091,0.81,0.137,1.392,0.137c0.546,0,0.991-0.046,1.337-0.137c0.346-0.091,0.619-0.264,0.819-0.519
                    c0.2-0.255,0.328-0.609,0.382-1.064s0.082-1.065,0.082-1.829v-9.991h6.06v9.991c0,2.038-0.118,3.657-0.354,4.858
                    c-0.237,1.201-0.683,2.111-1.338,2.729c-0.655,0.62-1.547,1.028-2.675,1.229c-1.129,0.2-2.566,0.301-4.313,0.301
                    c-1.856,0-3.367-0.101-4.531-0.301c-1.166-0.2-2.075-0.618-2.73-1.255s-1.101-1.557-1.337-2.758
                    c-0.237-1.201-0.355-2.802-0.355-4.804v-26.042c0-1.965,0.127-3.548,0.382-4.75c0.254-1.201,0.718-2.12,1.392-2.757
                    c0.673-0.636,1.592-1.055,2.757-1.255c1.165-0.2,2.639-0.3,4.422-0.3c1.747,0,3.184,0.101,4.313,0.3
                    c1.127,0.2,2.02,0.619,2.675,1.255c0.655,0.637,1.101,1.547,1.338,2.73c0.236,1.183,0.354,2.776,0.354,4.777v9.772H79.74z"/>
       </g>
       <g>
           <path d="M103.925,180.369v-9.608h-5.296v9.608h-6.114v-34.613c0-1.965,0.118-3.548,0.355-4.75c0.236-1.201,0.682-2.12,1.337-2.757
                    c0.655-0.636,1.537-1.055,2.648-1.255c1.109-0.2,2.539-0.3,4.286-0.3c1.819,0,3.321,0.109,4.504,0.328
                    c1.182,0.218,2.11,0.646,2.784,1.283c0.673,0.637,1.137,1.547,1.392,2.73c0.254,1.183,0.382,2.757,0.382,4.722v34.613H103.925z
                    M101.25,142.317c-0.546,0-0.992,0.046-1.337,0.137c-0.346,0.091-0.619,0.255-0.819,0.491c-0.201,0.237-0.328,0.583-0.382,1.037
                    c-0.055,0.456-0.082,1.047-0.082,1.774v19.873h5.296v-19.873c0-0.728-0.037-1.319-0.109-1.774c-0.073-0.455-0.2-0.8-0.382-1.037
                    c-0.183-0.236-0.456-0.4-0.819-0.491C102.25,142.363,101.795,142.317,101.25,142.317z"/>
       </g>
       <g>
           <path d="M130.512,142.481h-4.695v37.888h-6.278v-37.888h-4.75v-5.241h15.723V142.481z"/>
       </g>
       <g>
           <path d="M148.581,180.369h-12.338V137.24h12.284v5.187h-6.005v6.278h5.841v4.967h-5.841v21.292h6.06V180.369z"/>
       </g>
       <g>
           <path d="M161.248,180.369h-6.278V137.24h8.953c1.856,0,3.366,0.101,4.531,0.3c1.164,0.2,2.083,0.619,2.757,1.255
                    c0.673,0.637,1.128,1.529,1.365,2.675c0.236,1.146,0.354,2.667,0.354,4.559v4.531c0,1.129-0.118,2.047-0.354,2.757
                    c-0.237,0.71-0.546,1.283-0.929,1.72c-0.382,0.437-0.801,0.756-1.255,0.955c-0.456,0.201-0.901,0.355-1.338,0.465v0.218
                    c0.582,0.146,1.109,0.337,1.583,0.573c0.473,0.237,0.882,0.601,1.229,1.092c0.346,0.491,0.609,1.119,0.792,1.884
                    c0.182,0.765,0.272,1.747,0.272,2.948v17.197h-6.333v-18.18c0-1.165-0.146-1.975-0.437-2.43c-0.292-0.455-0.982-0.683-2.074-0.683
                    h-2.839V180.369z M161.248,154.109h2.839c1.019,0,1.692-0.2,2.02-0.601s0.491-1.128,0.491-2.184v-5.296
                    c0-0.691-0.027-1.246-0.082-1.665c-0.055-0.418-0.173-0.755-0.354-1.01c-0.183-0.254-0.455-0.428-0.819-0.519
                    c-0.364-0.09-0.837-0.136-1.419-0.136h-2.675V154.109z"/>
       </g>
       <g>
           <path d="M186.415,180.369h-6.278V137.24h6.278V180.369z"/>
       </g>
       <g>
           <path d="M206.068,180.369l-6.496-26.915h-0.219v26.915h-5.404V137.24h6.933l6.225,27.679h0.218V137.24h5.46v43.129H206.068z"/>
       </g>
       <g>
           <path d="M231.4,149.687v-3.931c0-0.728-0.027-1.319-0.082-1.774c-0.055-0.455-0.183-0.8-0.383-1.037
                    c-0.2-0.236-0.473-0.4-0.818-0.491c-0.347-0.091-0.792-0.137-1.338-0.137c-0.582,0-1.047,0.046-1.392,0.137
                    c-0.347,0.091-0.62,0.264-0.819,0.519c-0.2,0.255-0.327,0.61-0.382,1.064c-0.055,0.456-0.082,1.029-0.082,1.72v26.042
                    c0,0.729,0.036,1.329,0.109,1.802c0.072,0.473,0.209,0.837,0.409,1.092c0.199,0.255,0.491,0.428,0.873,0.519
                    c0.383,0.091,0.901,0.137,1.557,0.137c0.546,0,1.046-0.019,1.501-0.055s0.792-0.091,1.01-0.164v-19.817h5.896v25.059
                    c-0.51,0.109-1.493,0.227-2.948,0.354c-1.456,0.127-3.166,0.191-5.132,0.191c-2.002,0-3.631-0.109-4.886-0.328
                    c-1.256-0.218-2.23-0.654-2.921-1.31c-0.692-0.655-1.156-1.584-1.393-2.784c-0.237-1.201-0.354-2.766-0.354-4.695v-26.042
                    c0-1.965,0.127-3.548,0.382-4.75c0.254-1.201,0.719-2.12,1.393-2.757c0.673-0.636,1.592-1.055,2.757-1.255
                    c1.164-0.2,2.639-0.3,4.422-0.3c1.747,0,3.185,0.101,4.313,0.3c1.127,0.2,2.02,0.619,2.675,1.255
                    c0.655,0.637,1.101,1.547,1.338,2.73c0.236,1.183,0.354,2.776,0.354,4.777v3.931H231.4z"/>
       </g>
   </g>
   <g>
       <rect x="67.95" y="183.701" width="171.197" height="1"/>
   </g>
   <g>
       <g>
           <path d="M68.792,188.196c-0.046-0.163,0.047-0.279,0.21-0.279h1.074c0.093,0,0.187,0.081,0.21,0.163l1.214,4.657h0.046
                    l1.856-4.809c0.023-0.07,0.082-0.129,0.199-0.129h0.21c0.117,0,0.175,0.059,0.198,0.129l1.868,4.809h0.047l1.19-4.657
                    c0.023-0.082,0.117-0.163,0.21-0.163h1.074c0.164,0,0.257,0.116,0.21,0.279l-2.206,7.845c-0.023,0.094-0.117,0.163-0.21,0.163
                    h-0.187c-0.105,0-0.175-0.058-0.198-0.128l-2.089-5.288H73.66l-2.066,5.288c-0.035,0.07-0.082,0.128-0.199,0.128h-0.187
                    c-0.093,0-0.187-0.069-0.21-0.163L68.792,188.196z"/>
       </g>
       <g>
           <path d="M79.978,188.139c0-0.117,0.094-0.222,0.222-0.222h4.751c0.128,0,0.222,0.104,0.222,0.222v0.957
                    c0,0.116-0.094,0.222-0.222,0.222h-3.456v1.914h2.883c0.117,0,0.222,0.105,0.222,0.222v0.969c0,0.129-0.105,0.223-0.222,0.223
                    h-2.883v2.042h3.456c0.128,0,0.222,0.105,0.222,0.222v0.958c0,0.116-0.094,0.222-0.222,0.222H80.2
                    c-0.128,0-0.222-0.105-0.222-0.222V188.139z"/>
       </g>
       <g>
           <path d="M86.843,188.139c0-0.117,0.094-0.222,0.21-0.222h2.767c2.252,0,4.097,1.832,4.097,4.073c0,2.265-1.845,4.098-4.097,4.098
                    h-2.767c-0.117,0-0.21-0.105-0.21-0.222V188.139z M89.68,194.663c1.517,0,2.626-1.144,2.626-2.673
                    c0-1.518-1.109-2.661-2.626-2.661h-1.331v5.334H89.68z"/>
       </g>
       <g>
           <path d="M95.459,188.139c0-0.117,0.094-0.222,0.21-0.222h2.767c2.252,0,4.097,1.832,4.097,4.073c0,2.265-1.845,4.098-4.097,4.098
                    H95.67c-0.117,0-0.21-0.105-0.21-0.222V188.139z M98.296,194.663c1.518,0,2.626-1.144,2.626-2.673
                    c0-1.518-1.109-2.661-2.626-2.661h-1.331v5.334H98.296z"/>
       </g>
       <g>
           <path d="M104.076,188.139c0-0.117,0.105-0.222,0.222-0.222h1.085c0.117,0,0.222,0.104,0.222,0.222v7.728
                    c0,0.116-0.105,0.222-0.222,0.222h-1.085c-0.117,0-0.222-0.105-0.222-0.222V188.139z"/>
       </g>
       <g>
           <path d="M107.73,188.01c0-0.116,0.105-0.21,0.222-0.21h0.292l4.856,5.159h0.012v-4.82c0-0.117,0.093-0.222,0.222-0.222h1.074
                    c0.117,0,0.222,0.104,0.222,0.222v7.855c0,0.117-0.105,0.21-0.222,0.21h-0.28l-4.879-5.357h-0.012v5.02
                    c0,0.116-0.093,0.222-0.222,0.222h-1.062c-0.117,0-0.222-0.105-0.222-0.222V188.01z"/>
       </g>
       <g>
           <path d="M120.363,187.8c1.05,0,2.054,0.408,2.848,1.086c0.093,0.093,0.105,0.233,0.012,0.326l-0.724,0.759
                    c-0.093,0.094-0.198,0.094-0.303,0c-0.502-0.443-1.121-0.677-1.763-0.677c-1.459,0-2.615,1.249-2.615,2.708
                    c0,1.447,1.156,2.673,2.626,2.673c0.654,0,1.156-0.198,1.389-0.291v-0.992h-0.934c-0.128,0-0.222-0.094-0.222-0.211v-0.922
                    c0-0.128,0.093-0.222,0.222-0.222h2.241c0.117,0,0.21,0.105,0.21,0.222v2.988c0,0.094-0.047,0.152-0.094,0.187
                    c0,0-1.202,0.759-2.895,0.759c-2.323,0-4.191-1.844-4.191-4.179S118.04,187.8,120.363,187.8z"/>
       </g>
       <g>
           <path d="M129.061,191.664v-0.023l-0.245-0.269c-0.467-0.49-0.805-0.992-0.794-1.682c0-0.887,0.736-1.891,2.207-1.891
                    c1.307,0,2.206,0.853,2.206,1.902c0,1.203-1.19,1.915-1.424,2.125l1.343,1.354c0.187-0.373,0.338-0.852,0.42-1.295
                    c0.047-0.245,0.117-0.374,0.385-0.292l0.642,0.187c0.304,0.082,0.222,0.339,0.198,0.455c-0.198,0.841-0.572,1.576-0.724,1.856
                    l1.039,0.98c0.14,0.14,0.257,0.269-0.023,0.537l-0.479,0.49c-0.14,0.128-0.327,0.151-0.514-0.035l-0.84-0.853
                    c-0.21,0.245-1.027,0.992-2.545,0.992c-1.482,0-2.463-1.004-2.463-2.194C127.45,192.469,128.851,191.792,129.061,191.664z
                    M130.065,194.955c0.875,0,1.331-0.49,1.506-0.688l-1.739-1.845c-0.187,0.117-0.992,0.502-0.992,1.437
                    C128.839,194.5,129.376,194.955,130.065,194.955z M131.174,189.772c0-0.443-0.385-0.875-0.992-0.875
                    c-0.537,0-0.864,0.361-0.864,0.793c-0.012,0.409,0.257,0.678,0.607,1.051l0.257,0.257
                    C130.357,190.894,131.174,190.496,131.174,189.772z"/>
       </g>
       <g>
           <path d="M138.273,188.139c0-0.117,0.094-0.222,0.222-0.222h4.751c0.128,0,0.222,0.104,0.222,0.222v0.957
                    c0,0.116-0.094,0.222-0.222,0.222h-3.456v1.914h2.883c0.117,0,0.222,0.105,0.222,0.222v0.969c0,0.129-0.105,0.223-0.222,0.223
                    h-2.883v2.042h3.456c0.128,0,0.222,0.105,0.222,0.222v0.958c0,0.116-0.094,0.222-0.222,0.222h-4.751
                    c-0.128,0-0.222-0.105-0.222-0.222V188.139z"/>
       </g>
       <g>
           <path d="M144.077,188.22c-0.07-0.151,0.023-0.303,0.198-0.303h1.191c0.105,0,0.175,0.069,0.198,0.128l2.241,5.02h0.082l2.241-5.02
                    c0.023-0.059,0.093-0.128,0.198-0.128h1.19c0.176,0,0.269,0.151,0.199,0.303l-3.619,7.856c-0.035,0.07-0.093,0.128-0.199,0.128
                    h-0.117c-0.105,0-0.163-0.058-0.198-0.128L144.077,188.22z"/>
       </g>
       <g>
           <path d="M152.88,188.139c0-0.117,0.094-0.222,0.222-0.222h4.751c0.129,0,0.222,0.104,0.222,0.222v0.957
                    c0,0.116-0.093,0.222-0.222,0.222h-3.455v1.914h2.883c0.117,0,0.222,0.105,0.222,0.222v0.969c0,0.129-0.104,0.223-0.222,0.223
                    h-2.883v2.042h3.455c0.129,0,0.222,0.105,0.222,0.222v0.958c0,0.116-0.093,0.222-0.222,0.222h-4.751
                    c-0.128,0-0.222-0.105-0.222-0.222V188.139z"/>
       </g>
       <g>
           <path d="M159.745,188.01c0-0.116,0.105-0.21,0.222-0.21h0.292l4.855,5.159h0.012v-4.82c0-0.117,0.094-0.222,0.222-0.222h1.074
                    c0.117,0,0.222,0.104,0.222,0.222v7.855c0,0.117-0.104,0.21-0.222,0.21h-0.28l-4.879-5.357h-0.012v5.02
                    c0,0.116-0.094,0.222-0.222,0.222h-1.063c-0.116,0-0.222-0.105-0.222-0.222V188.01z"/>
       </g>
       <g>
           <path d="M169.868,189.317h-1.786c-0.129,0-0.222-0.105-0.222-0.222v-0.957c0-0.117,0.093-0.222,0.222-0.222h5.101
                    c0.129,0,0.222,0.104,0.222,0.222v0.957c0,0.116-0.093,0.222-0.222,0.222h-1.786v6.549c0,0.116-0.104,0.222-0.222,0.222h-1.085
                    c-0.117,0-0.222-0.105-0.222-0.222V189.317z"/>
       </g>
       <g>
           <path d="M174.141,194.943l0.421-0.724c0.093-0.163,0.292-0.163,0.396-0.081c0.059,0.034,1.004,0.724,1.763,0.724
                    c0.607,0,1.063-0.397,1.063-0.899c0-0.595-0.502-1.004-1.482-1.4c-1.098-0.443-2.194-1.145-2.194-2.521
                    c0-1.039,0.77-2.241,2.626-2.241c1.19,0,2.102,0.607,2.335,0.782c0.116,0.07,0.151,0.269,0.069,0.385l-0.443,0.666
                    c-0.093,0.14-0.269,0.233-0.408,0.14c-0.094-0.059-0.98-0.642-1.623-0.642c-0.665,0-1.027,0.443-1.027,0.816
                    c0,0.549,0.433,0.923,1.378,1.308c1.132,0.455,2.439,1.133,2.439,2.639c0,1.202-1.039,2.311-2.685,2.311
                    c-1.471,0-2.335-0.688-2.568-0.91C174.095,195.188,174.036,195.131,174.141,194.943z"/>
       </g>
       <g>
           <path d="M187.146,187.8c1.179,0,2.031,0.374,2.825,1.086c0.104,0.093,0.104,0.233,0.012,0.326l-0.713,0.735
                    c-0.081,0.094-0.198,0.094-0.291,0c-0.49-0.432-1.145-0.688-1.798-0.688c-1.494,0-2.604,1.249-2.604,2.72
                    c0,1.459,1.121,2.696,2.615,2.696c0.7,0,1.296-0.269,1.786-0.665c0.093-0.082,0.222-0.07,0.291,0l0.725,0.747
                    c0.093,0.082,0.069,0.233-0.012,0.315c-0.794,0.771-1.798,1.132-2.837,1.132c-2.335,0-4.214-1.855-4.214-4.19
                    S184.812,187.8,187.146,187.8z"/>
       </g>
       <g>
           <path d="M190.452,195.784l3.63-7.855c0.035-0.07,0.094-0.129,0.198-0.129h0.117c0.116,0,0.163,0.059,0.198,0.129l3.596,7.855
                    c0.069,0.151-0.023,0.304-0.199,0.304h-1.015c-0.176,0-0.257-0.07-0.339-0.233l-0.572-1.261h-3.49l-0.571,1.261
                    c-0.047,0.116-0.152,0.233-0.339,0.233h-1.016C190.476,196.088,190.382,195.936,190.452,195.784z M195.495,193.298l-1.168-2.568
                    h-0.035l-1.144,2.568H195.495z"/>
       </g>
       <g>
           <path d="M199.921,189.317h-1.786c-0.129,0-0.222-0.105-0.222-0.222v-0.957c0-0.117,0.093-0.222,0.222-0.222h5.101
                    c0.129,0,0.222,0.104,0.222,0.222v0.957c0,0.116-0.093,0.222-0.222,0.222h-1.786v6.549c0,0.116-0.104,0.222-0.222,0.222h-1.085
                    c-0.117,0-0.222-0.105-0.222-0.222V189.317z"/>
       </g>
       <g>
           <path d="M204.672,188.139c0-0.117,0.094-0.222,0.223-0.222h4.751c0.128,0,0.222,0.104,0.222,0.222v0.957
                    c0,0.116-0.094,0.222-0.222,0.222h-3.456v1.914h2.884c0.116,0,0.222,0.105,0.222,0.222v0.969c0,0.129-0.105,0.223-0.222,0.223
                    h-2.884v2.042h3.456c0.128,0,0.222,0.105,0.222,0.222v0.958c0,0.116-0.094,0.222-0.222,0.222h-4.751
                    c-0.129,0-0.223-0.105-0.223-0.222V188.139z"/>
       </g>
       <g>
           <path d="M211.537,188.139c0-0.117,0.094-0.222,0.223-0.222h3.338c1.401,0,2.545,1.12,2.545,2.509c0,1.074-0.712,1.95-1.728,2.358
                    l1.599,2.965c0.082,0.151,0,0.339-0.198,0.339h-1.226c-0.104,0-0.163-0.059-0.187-0.105l-1.553-3.093h-1.296v2.977
                    c0,0.116-0.104,0.222-0.222,0.222h-1.073c-0.129,0-0.223-0.105-0.223-0.222V188.139z M214.97,191.641
                    c0.618,0,1.155-0.537,1.155-1.191c0-0.618-0.537-1.132-1.155-1.132h-1.903v2.323H214.97z"/>
       </g>
       <g>
           <path d="M219.313,188.139c0-0.117,0.094-0.222,0.222-0.222h4.751c0.129,0,0.222,0.104,0.222,0.222v0.957
                    c0,0.116-0.093,0.222-0.222,0.222h-3.455v1.914h2.883c0.117,0,0.222,0.105,0.222,0.222v0.969c0,0.129-0.104,0.223-0.222,0.223
                    h-2.883v2.042h3.455c0.129,0,0.222,0.105,0.222,0.222v0.958c0,0.116-0.093,0.222-0.222,0.222h-4.751
                    c-0.128,0-0.222-0.105-0.222-0.222V188.139z"/>
       </g>
       <g>
           <path d="M226.179,188.139c0-0.117,0.094-0.222,0.222-0.222h3.339c1.4,0,2.544,1.12,2.544,2.509c0,1.074-0.712,1.95-1.728,2.358
                    l1.6,2.965c0.082,0.151,0,0.339-0.198,0.339h-1.226c-0.105,0-0.164-0.059-0.188-0.105l-1.552-3.093h-1.296v2.977
                    c0,0.116-0.105,0.222-0.222,0.222H226.4c-0.128,0-0.222-0.105-0.222-0.222V188.139z M229.61,191.641
                    c0.619,0,1.156-0.537,1.156-1.191c0-0.618-0.537-1.132-1.156-1.132h-1.902v2.323H229.61z"/>
       </g>
       <g>
           <path d="M233.476,194.943l0.421-0.724c0.093-0.163,0.292-0.163,0.396-0.081c0.059,0.034,1.004,0.724,1.763,0.724
                    c0.607,0,1.063-0.397,1.063-0.899c0-0.595-0.502-1.004-1.482-1.4c-1.098-0.443-2.194-1.145-2.194-2.521
                    c0-1.039,0.77-2.241,2.626-2.241c1.19,0,2.102,0.607,2.335,0.782c0.116,0.07,0.151,0.269,0.069,0.385l-0.443,0.666
                    c-0.093,0.14-0.269,0.233-0.408,0.14c-0.094-0.059-0.98-0.642-1.623-0.642c-0.665,0-1.027,0.443-1.027,0.816
                    c0,0.549,0.433,0.923,1.378,1.308c1.132,0.455,2.439,1.133,2.439,2.639c0,1.202-1.039,2.311-2.685,2.311
                    c-1.471,0-2.335-0.688-2.568-0.91C233.43,195.188,233.371,195.131,233.476,194.943z"/>
       </g>
   </g>
   <g>
       <g>
           <path fill="#939598" d="M77.058,86.308c0.068-0.614,0.683-1.091,1.228-1.091h1.092c0.341,0,0.955,0.273,1.16,0.682l15.828,29.472
                    h0.136l15.828-29.472c0.205-0.409,0.819-0.682,1.16-0.682h1.092c0.545,0,1.16,0.478,1.228,1.091l7.709,45.845
                    c0.136,0.887-0.478,1.501-1.296,1.501h-8.05c-0.614,0-1.228-0.546-1.296-1.091l-3.07-21.081h-0.136l-11.461,22.104
                    c-0.205,0.41-0.818,0.75-1.16,0.75h-1.228c-0.409,0-0.955-0.341-1.16-0.75l-11.53-22.104h-0.136l-3.002,21.081
                    c-0.068,0.545-0.614,1.091-1.296,1.091h-8.05c-0.819,0-1.433-0.614-1.296-1.501L77.058,86.308z"/>
           <path d="M97.047,135.242h-1.228c-0.767,0-1.613-0.538-1.969-1.251l-10.328-19.799l-2.634,18.5
                    c-0.127,1.023-1.111,1.869-2.192,1.869h-8.05c-0.676,0-1.301-0.275-1.713-0.756c-0.412-0.48-0.582-1.115-0.478-1.788l7.711-45.858
                    c0.107-0.978,1.082-1.847,2.121-1.847h1.092c0.663,0,1.604,0.452,1.969,1.183l15.086,28.09l15.099-28.114
                    c0.353-0.707,1.294-1.159,1.957-1.159h1.092c1.039,0,2.013,0.869,2.127,1.896l7.702,45.795c0.105,0.686-0.064,1.32-0.476,1.8
                    c-0.412,0.48-1.037,0.756-1.713,0.756h-8.05c-1.062,0-2.067-0.863-2.194-1.885l-2.693-18.486L99.01,134.002
                    C98.643,134.739,97.696,135.242,97.047,135.242z M82.208,110.577h1.47l11.783,22.591c0.058,0.114,0.271,0.253,0.368,0.264l1.189,0
                    c0.117-0.029,0.331-0.17,0.381-0.253l11.719-22.601h1.469l3.183,21.856c0.025,0.12,0.244,0.316,0.4,0.316h8.05
                    c0.147,0,0.271-0.045,0.339-0.124c0.08-0.093,0.079-0.233,0.063-0.334l-7.707-45.833c-0.022-0.185-0.232-0.336-0.335-0.336h-1.092
                    c-0.093,0.01-0.305,0.122-0.357,0.194l-16.088,29.959h-1.219L79.74,86.327c-0.057-0.083-0.269-0.195-0.377-0.206l-1.077,0.001
                    c-0.103,0-0.313,0.151-0.329,0.286l-7.716,45.895c-0.014,0.088-0.015,0.229,0.065,0.322c0.067,0.079,0.191,0.124,0.339,0.124h8.05
                    c0.225,0,0.386-0.207,0.398-0.298L82.208,110.577z"/>
       </g>
       <g>
           <path fill="#939598" d="M132.25,87.536c0-0.887,0.682-1.637,1.637-1.637h7.982c0.887,0,1.637,0.75,1.637,1.637v17.942
                                   l15.146-18.897c0.273-0.341,0.887-0.682,1.297-0.682h8.596c1.296,0,1.979,1.501,1.228,2.524l-15.964,19.989l17.396,22.65
                                   c0.751,1.023,0,2.524-1.296,2.524h-9.415c-0.478,0-1.091-0.273-1.228-0.478l-15.76-21.421v20.33c0,0.887-0.75,1.637-1.637,1.637
                                   h-7.982c-0.955,0-1.637-0.75-1.637-1.637V87.536z"/>
           <path d="M141.87,134.56h-7.982c-1.426,0-2.542-1.117-2.542-2.542V87.536c0-1.426,1.117-2.542,2.542-2.542h7.982
                    c1.378,0,2.542,1.165,2.542,2.542v15.366l13.534-16.887c0.402-0.502,1.261-1.021,2.003-1.021h8.596
                    c0.921,0,1.753,0.513,2.171,1.337c0.432,0.853,0.35,1.86-0.214,2.627l-15.544,19.464l16.965,22.088
                    c0.563,0.766,0.639,1.757,0.211,2.603c-0.431,0.85-1.283,1.377-2.225,1.377h-9.415c-0.67,0-1.617-0.336-1.98-0.88l-14.102-19.167
                    v17.573C144.412,133.395,143.248,134.56,141.87,134.56z M133.888,86.804c-0.48,0-0.732,0.368-0.732,0.732v44.481
                    c0,0.364,0.251,0.732,0.732,0.732h7.982c0.39,0,0.732-0.342,0.732-0.732V108.93l17.356,23.591c0.107,0.064,0.364,0.16,0.536,0.16
                    h9.415c0.374,0,0.551-0.269,0.609-0.384c0.045-0.089,0.175-0.402-0.043-0.699l-17.816-23.196l16.406-20.543
                    c0.166-0.227,0.131-0.521,0.035-0.709c-0.065-0.129-0.225-0.345-0.556-0.345h-8.596c-0.129,0.011-0.474,0.196-0.591,0.343
                    l-16.757,20.908V87.536c0-0.39-0.342-0.732-0.732-0.732H133.888z"/>
       </g>
   </g>
   <g>
       <g>
           <path fill="#939598" d="M228.62,68.926c-0.044,0.007-0.087,0.025-0.131,0.062C228.565,68.982,228.604,68.959,228.62,68.926z"/>
       </g>
       <g>
           <path fill="#939598" d="M246.344,88.774l-0.237-0.209C246.235,88.721,246.301,88.77,246.344,88.774z"/>
       </g>
       <g>
           <path fill="#939598" d="M215.697,59.404c0.024,0.002,0.028-0.005,0.048-0.007c-0.21-0.132-0.496-0.31-0.96-0.6L215.697,59.404z"/>
       </g>
       <g>
           <path fill="#939598" d="M265.929,140.996l-0.047-0.349C265.899,140.804,265.898,140.908,265.929,140.996z"/>
       </g>
       <g>
           <path fill="#939598" d="M257.722,108.774c0.091,0.073,0.179,0.226,0.263,0.375C257.907,108.947,257.827,108.793,257.722,108.774z"
                 />
       </g>
       <g>
           <path fill="#939598" d="M209.5,52.783l0.339,0.372C209.869,53.076,209.757,52.93,209.5,52.783z"/>
       </g>
       <g>
           <path fill="#939598" d="M193.755,47.234c0.009,0.017,0.009,0.029,0.029,0.054C193.813,47.278,193.792,47.257,193.755,47.234z"/>
       </g>
       <g>
           <path fill="#939598" d="M199.607,50.112c0.135,0.037,0.296,0.067,0.459,0.098C199.883,50.141,199.65,50.045,199.607,50.112z"/>
       </g>
       <g>
           <path fill="#939598" d="M200.288,50.25c-0.072-0.013-0.146-0.026-0.222-0.04C200.183,50.251,200.279,50.282,200.288,50.25z"/>
       </g>
       <g>
           <path fill="#939598" d="M135.519,257.881c-0.451-0.111-0.871-0.06-1.308-0.037L135.519,257.881z"/>
       </g>
       <g>
           <path fill="#939598" d="M259.466,190.929l-0.036-0.121C259.419,190.905,259.438,190.93,259.466,190.929z"/>
       </g>
       <g>
           <path fill="#939598" d="M134.573,259.363l-0.316-0.112C134.369,259.309,134.472,259.342,134.573,259.363z"/>
       </g>
       <g>
           <path fill="#939598" d="M217.533,56.619c0.515,0.09,1.255,0.792,1.343,0.633C218.419,57.013,217.863,56.518,217.533,56.619z"/>
       </g>
       <g>
           <path fill="#939598" d="M261.457,203.909l-0.141,0.294C261.39,204.066,261.431,203.978,261.457,203.909z"/>
       </g>
       <g>
           <path fill="#939598" d="M134.573,259.363l0.367,0.13c-0.023-0.041-0.06-0.078-0.092-0.117
                                   C134.758,259.384,134.666,259.381,134.573,259.363z"/>
       </g>
       <g>
           <path fill="#939598" d="M229.336,233.705l0.014-0.012c0.021-0.034,0.037-0.06,0.06-0.095L229.336,233.705z"/>
       </g>
       <g>
           <path fill="#939598" d="M227.005,235.28c0.137-0.142,0.27-0.279,0.416-0.432C227.168,235.037,227.063,235.169,227.005,235.28z"/>
       </g>
       <g>
           <path fill="#939598" d="M134.211,257.844l-0.75-0.021C133.724,257.86,133.97,257.856,134.211,257.844z"/>
       </g>
       <g>
           <path fill="#939598" d="M201.382,251.045c0.085-0.047,0.166-0.091,0.244-0.134C201.516,250.958,201.436,251.002,201.382,251.045z"
                 />
       </g>
       <g>
           <path fill="#939598" d="M256.917,106.779c-0.015-0.07-0.048-0.16-0.072-0.239C256.794,106.534,256.773,106.562,256.917,106.779z"
                 />
       </g>
       <g>
           <polygon fill="#939598" points="268.882,125.339 268.884,125.347 268.888,125.346 		"/>
       </g>
       <g>
           <path fill="#939598" d="M268.348,124.265c0.056,0.365,0.325,0.837,0.534,1.074C268.784,124.981,268.653,124.656,268.348,124.265z"
                 />
       </g>
       <g>
           <path fill="#939598" d="M269.365,127.82c-0.003-0.047-0.005-0.084-0.008-0.141C269.355,127.732,269.357,127.774,269.365,127.82z"
                 />
       </g>
       <g>
           <path fill="#939598" d="M262.046,105.326c-0.005,0.002-0.014,0.002-0.018,0.008c0.009,0.003,0.014,0.005,0.021,0.005
                                   L262.046,105.326z"/>
       </g>
       <g>
           <path fill="#939598" d="M263.96,109.62c0,0.09,0.02,0.207,0.052,0.329C264.015,109.862,264,109.751,263.96,109.62z"/>
       </g>
       <g>
           <path fill="#939598" d="M271.901,139.239c-0.002-0.005-0.009-0.004-0.009-0.004c0.003,0.011,0.003,0.013,0.006,0.024
                                   C271.899,139.251,271.9,139.246,271.901,139.239z"/>
       </g>
       <g>
           <path className="crest crest__worn" fill="#939598" d="M269.897,183.556c0.612-2.17,1.018-4.731,1.509-7.258c0.411-2.542,0.821-5.076,1.178-7.275
            c-0.26-1.016-0.098-2.412-0.006-3.824l0.526-0.094c0,0-0.004-0.298-0.008-0.596c0.001-0.298-0.021-0.597-0.021-0.597
            c0.196-0.465,0.016,0.916,0.283,0.087l-0.282-0.477c0.189-0.074,0.35-3.023,0.565-1.009c0,0,0.088-1.15,0.175-2.301
            c0.043-0.575,0.087-1.15,0.119-1.582c0.014-0.433,0.023-0.721,0.023-0.721c0.042-1.02-0.41-0.612-0.255-1.732
            c0.155-0.225,0.092,0.408,0.277,0.321c0.232-1.688,0.012-2.674-0.234-3.62c-0.288-0.947-0.594-1.855-0.516-3.426l0.075,0.265
            c-0.149-0.53-0.156-1.545-0.229-1.555c-0.505,0.422,0.293,0.552,0.012,1.519c-0.435-0.072-0.262-1.182-0.59-1.108
            c0.137-0.735-0.106-1.155-0.188-1.786c0.192-1.096-0.114-2.636,0.165-3.48c-0.188-0.519-0.612-1.076-0.785-2.202
            c0.238-0.847,0.346,0.158,0.337-0.963l-0.272-0.149c-0.072-0.91,0.016-0.777,0.137-0.758c-0.108-0.973-0.441-0.763-0.43-1.999
            l0.325,0.646c-0.21-0.888-0.188-1.263-0.394-2.51c0.147,0.005,0.224,0.507,0.245,0.879c0.252-0.733-0.358-1.134-0.381-1.505
            c-0.091,0.122,0.008,0.991-0.309,0.608c-0.062-0.623-0.562-1.253-0.345-1.743l0.354,0.632c-0.414-1.253-0.428-0.633-0.586-2.123
            c0.032,0.076,0.087,0.026,0.145,0.049c-0.453-0.877-0.46-2.133-0.735-3.507l0.123,0.245c-0.206-0.534-0.492-0.786-0.541-1.086
            c0.031,0.809-0.088,0.613-0.309,0.736c0.162-1.114-0.781-1.461-0.981-3.167c0.143-0.008,0.433,0.345,0.393,0.839
            c0.541,0.47,0.908,0.457,1.331,1.438c-0.238-0.616-0.463-1.353-0.254-1.236l-0.429-0.106c-0.092-0.384-0.164-0.688-0.232-0.978
            c-0.287-0.1-0.188,0.868-0.588,0.029c-0.395-0.958,0.25-0.628,0.034-0.738c-0.525-1.325-0.565-0.454-0.798-1.8
            c0.068,0.122,0.204,0.359,0.199,0.482c-0.106-0.983-0.694-2.291-0.326-2.701c0.148,0.107,0.293,0.092,0.352,0.463l-0.074,0.006
            c0.618,0.941-0.327-0.841,0.165-0.637c-0.223,0.145-0.593-1.183-0.74-1.788l-0.218,0.276c-1.47-1.693,0.585-0.071-0.156-1.983
            c-0.589-1.173,0.075,0.493-0.366-0.199c-0.222-0.85-0.068-1.252-0.653-2.408c-0.018-0.374-0.13-1.112,0.153-1.286
            c-0.401-0.685-0.709-2.283-1.163-2.581c0.22,1.352,0.793,1.125,0.951,2.741l-0.453-0.297c-0.379-1.012-0.981-2.26-1.19-2.979
            c0.003,0.162-0.047,0.255-0.147,0.193c-0.724-1.363-1.192-1.64-1.696-3.044c0.188-0.299,0.49,0.522,0.768,1.096
            c-0.169-0.977-0.385-0.931-0.895-2.34c0.201-0.179,0.388,0.93,0.686,0.985c0,0-0.185-0.372-0.368-0.744
            c-0.179-0.374-0.378-0.739-0.378-0.739c0.017,0,0.03-0.014,0.048-0.022c-0.493-0.034-0.524-1.149-1.087-2.005
            c-1.06-2.156-1.716-2.231-2.667-3.217c-0.07-0.365-0.024-0.509,0.142-0.429c0.117,0.603,0.197,0.441,0.395,0.568
            c-0.428-0.684-0.485-1.748-0.116-1.435c-0.731-1.068,0.026,0.514-0.47-0.11l-0.826-1.554c-0.09,0.292,0.167,0.854-0.417,0.142
            c-0.416-0.644-0.562-0.985-1.081-1.84c0.168,0.068,0.119-0.44,0.503,0.208c-0.184-0.455-0.572-1.106-0.906-1.244
            c0.404,1.035-1.102-1.431-0.962-0.557l-0.404-1.035c0.127,0.214,0.265,0.161,0.421,0.497c0.118-0.441-0.549-0.712-0.775-1.019
            c-0.133-0.372-0.692-1.2-0.626-1.343c-0.272-0.235-0.688-0.973-0.638-1.2c-0.345-0.389-0.202,0.088-0.376,0.024l-0.963-1.459
            c0.099-0.183,0.431-0.599,0.638-0.419c-0.52-0.449-0.567,0.11-1.138-0.713c0.635,0.253-0.577-1.221,0.268-0.803
            c-0.136-0.615-1.14-1.929-1.761-2.595c-0.563-0.276,0.465,0.881,0.198,1.009c-1.604-1.976-2.337-1.586-3.417-3.094l0.245,0.421
            c-0.865-0.784-0.755-0.707-1.371-1.066c0.46,0.035-0.062-1.67-1.429-3.012c-0.243,0.002,0.462,1.014,0.039,0.973l-0.891-0.893
            c-0.041-0.086,0.097-0.024,0.241,0.065c-0.855-0.747-1.078-1.868-1.606-1.823l-0.396-1.209l0.146,0.491
            c-0.224-0.005-1.147-0.875-1.391-1.004c-1.06-0.773-1.281-1.21-1.626-1.873c0.057,0.265,0.218,0.897-0.541,0.223
            c0.412-0.409-1.36-1.662-1.151-1.838c-0.311-0.271-0.731-0.893-0.93-0.748l0.296-0.22c-0.414,0.307-1.511-0.713-2.422-1.412
            c0.642-0.341-0.961-0.813-0.967-1.593c-0.469,0.219-1.7-1.152-2.107-0.973c-0.698-0.542-0.358-0.824-0.44-1.083l-0.428-0.125
            c-0.195-0.473-1.509-1.498-2.222-2.201l-0.986-0.218l-0.164-0.354c-0.181-0.003-0.831-0.408-0.54-0.002l-0.384-0.314
            c-0.302,0.352-0.617-0.336-1.559-0.725c-0.149-0.186-0.038-0.241,0.176-0.17c-0.41-0.278-0.102-0.35-0.243-0.625
            c0.225,0.288-1.938-0.323-0.941,0.583c-0.027,0.202-0.746-0.116-1.104-0.451l-0.022-0.5l-0.717,0.033
            c-0.663-0.37-0.792-0.766-1.278-1.145c0.532,0.327,1.589,0.12,0.218-0.771c0.747,0.425,1.218,0.475,1.637,0.65
            c-0.273-0.201-0.395-0.329,0.14-0.179c-1.137-0.606-0.491-0.031-1.574-0.688c0.252-0.269,0.01-0.549-0.881-1.226l0.574,0.652
            c-0.057,0.018-0.122,0.016-0.188,0.001c0.249,0.195,0.296,0.288,0.279,0.44c-0.214-0.132-1.495-0.372-1.561-0.667
            c0.796,1.131-2.125-0.762-1.603-0.054c-0.177,0.017-0.939-0.414-0.927-0.618c1.04,0.303-0.476-0.763-0.205-0.895
            c-0.388-0.11,0.58,0.658,0.056,0.505c-0.545-0.309-0.846-0.713-1.102-0.978l0.266,0.064l-0.781-0.6
            c-0.227,0.083,0.684,0.711-0.151,0.373c-0.172-0.177-1.007-0.516-0.915-0.63c0.494-0.018,0.376-0.455,1.083-0.148
            c-0.575-0.277-2.382-1.191-1.816-0.507l-0.663-0.367c0.352,0.36,1.392,1.225,0.033,0.558l0.045-0.058l-1.053-0.25l-0.32-0.382
            c-0.027,0.086-0.216,0.091-0.564-0.08c-0.044-0.141-0.357-0.335-0.267-0.453c-1.033-0.465-1.028-0.261-1.207-0.228
            c-0.582-0.245-0.765-0.415-0.406-0.482c-0.766-0.41-2.149-1.02-2.614-1.433c-1.14-0.292,0.19,0.16-0.535,0.127
            c-0.682-0.947-3.444-1.717-5.244-2.291c0.291,0.688-0.51-0.114-1.009,0.243c0.445-0.027-1.545-0.999,0.068-0.593l-0.973-0.283
            c-0.552-0.517,1.655,0.344,0.81-0.396c-1.083-0.364-1.98-0.04-3.175-0.677c0.549-0.236-1.018-0.2-0.571-0.499
            c0.313-0.061,0.651,0.101,1.019,0.201l-0.198-0.151c-2.348-0.858-0.83,0.222-2.157,0.098c-1.464-0.384-1.056-0.636-1.088-0.837
            c-0.205-0.141-0.956-0.301-1.367-0.316c0.956,0.035,1.573,0.724,1.095,0.838c-0.514-0.618-2.145-0.676-2.582-0.623
            c1.938,1.067,1.429,0.448,3.221,1.509c0.306-0.056,0.38-0.451,1.494,0.053c-0.789-0.029-0.018,0.227,0.168,0.35
            c-0.15-0.294,0.834,0.154,1.208,0.201c0.258,0.289-0.85,0.263,0.273,0.758l0.392-0.436c0.427,0.237-0.32,0.307,0.684,0.525
            c-0.61,0.083,0.012,0.469-1.313,0.092c1.395,0.982,2.453,0.602,3.732,0.846c-0.136-0.012-0.175,0.051-0.309,0.042
            c0.307-0.037,0.776,0.122,1.137,0.237c0.738,0.431-0.706-0.021-0.559,0.208c0,0,0.323,0.109,0.638,0.241
            c0.316,0.126,0.633,0.252,0.633,0.252c0.02,0.027,0.021,0.038,0.018,0.049c0.358,0.067,0.693,0.152,0.759,0.324
            c-0.439,0.008-0.652,0.114-0.956,0.142l1.461,0.194c-0.211,0.106,0.972,0.731-0.016,0.467l1.979,0.731l0.02,0.402
            c0.491,0.132,0.534,0.072-0.006-0.198c0.636,0.33,3.162,0.765,2.521,1.244c1.612,0.454,2.136,0.747,3.985,1.529
            c-1.512-0.424,1.36,1.208-0.148,0.58c0.767,0.589,1.124,0.332,1.816,0.632c0.549,0.468,0.1,0.634-0.632,0.396l0.866,0.272
            l-0.05,0.056l0.783,0.184c0.63,0.557-0.023,0.4,0.698,0.843l0.317-0.186l0.399,0.458c-0.112-0.239,0.351-0.038,0.893,0.256
            l-0.047,0.056l0.674,0.331c-0.735-0.211,0.551,0.411,0.14,0.434c0.54,0.341,0.555,0.345,1.575,0.886l-0.058-0.292
            c1.393,0.617-0.11,0.297,0.908,0.964l0.073-0.256c1.227,1.331,2.614,1.452,3.541,2.399c-0.652-0.364-0.662-0.016-0.608-0.068
            c1.001,0.515,2.405,1.673,3.521,2.11c0.119,0.546,0.134,0.694,0.985,1.529c0.709,0.488,0.822,0.547,1.173,0.559
            c0.556,0.639,1.901,1.464,1.764,1.697c0.285-0.041,0.554,0.507,1.024,0.66l0.004,0.145l0.108-0.24
            c0.794,1.345,2.091,1.409,2.887,2.765l-0.236-0.122l0.533,0.347c-0.001,0.148-0.236,0.028-0.355,0.116
            c0,0,0.532,0.495,1.064,0.991c0.543,0.484,1.039,1.018,1.039,1.018l-0.274-0.542c0.452,0.413,0.918,0.527,1.308,1.134
            c-1.094-0.55,0.678,0.693,0.604,1.033l-0.513-0.369c0.62,0.583,0.62,0.583,0.752,0.354c0.852,0.719,0.193,0.874,1.113,1.401
            l-0.507-0.52c-0.047-0.255,0.475-0.032,0.815,0.315c0.057,0.106,0.203,0.443-0.069,0.19c0.659,0.577,1.449,1.632,1.783,1.847
            c0.261,0.403,0.021,0.409-0.2,0.267c1.422,1.226,0.536,0.647,1.953,1.887l-0.129,0.071l0.54,0.38l0.353,0.901l-0.067-0.373
            c0.41,0.454,0.733,0.683,1.126,1.286l-0.348-0.085c1.186,1.26,1.023,1.733,2.23,2.864c0.037,0.113-0.17-0.048-0.275-0.131
            c1.182,1.007,0.385,1.144,1.632,2.115c-0.115-0.032-0.086,0.153-0.171,0.143l0.529,0.471l-0.266,0.128l0.686,0.201l0.011,0.519
            l0.265,0.142c-0.036,0.931,0.896,1.555,1.121,2.622l0.205-0.347c0.517,0.421,0.961,0.951,1.366,1.542
            c0.382,0.607,0.715,1.282,1.05,1.973c0.666,1.385,1.342,2.826,2.21,4.104l-0.278,0.093l0.911,0.947
            c0.129,0.706-0.414-0.24-0.441,0.018c0.796,1.112,0.648,0.291,1.487,1.638c0.216,0.801-0.324-0.149-0.601-0.433
            c0.856,1.218,1.91,3.146,2.246,4.149c0.046,0.004,0.12,0.041,0.111-0.016c0.576,0.824,0.975,2.078,1.421,3.072
            c-0.132,0.029-0.26-0.209-0.393-0.447c0.141,0.366,0.269,0.893,0.526,1.146l-0.392-0.277c0.285,0.784,1.137,2.171,0.939,2.453
            c0.06-0.133,0.294-0.184,0.532,0.499c-0.271,0.535,0.869,1.77,1.058,2.937c-0.147-0.093-0.469-0.877-0.39-0.652
            c0.097,0.582,0.475,0.996,0.785,1.428l-0.224-0.086c0,0,0.315,0.603,0.568,1.229c0.267,0.623,0.533,1.245,0.533,1.245
            c-0.296,0.518,0.851,2.401,0.486,2.323c0.283,0.92-0.064-0.826,0.362,0.317c-0.063,0.358,0.396,1.032,0.106,1.064l-0.066-0.116
            l0.245,0.813l-0.412-0.438c0.251,0.688,0.664,1.128,0.883,1.107c0.03,0.712,0.416,1.631,0.25,2.001l-0.134-0.231
            c0.325,0.922,0.323,1.754,0.33,2.525c0.019,0.769,0.07,1.471,0.472,2.166c-0.014,0.119-0.087,0.121-0.136-0.112l0.243,1.173
            l-0.271-0.236c0,0,0.187,0.467,0.373,0.935c0.164,0.473,0.329,0.946,0.329,0.946l-0.146,0.001c0.144,1.303,0.631,3.297,0.87,4.966
            l-0.099-0.24c0.444,1.795,0.078,1.777,0.399,3.093c-0.044-0.043-0.063-0.086-0.078-0.134l0.153,1.2l-0.172-0.25
            c0.432,0.858-0.203,2.583,0.031,2.009c0.173,0.492,0.403,1.35,0.591,1.487c0.112,1.317-0.161,0.335-0.225,1.157
            c0.062,0.95,0.324,1.106,0.216,2.405l-0.146-0.021c0.04,0.603,0.152,0.975,0.194,1.817l-0.075-0.249
            c0.327,1.371-0.363,1.713,0.252,2.79c-0.132,0.576-0.07,1.672-0.358,1.612c0.13,1.712,0.239,3.051,0.192,4.754
            c-0.494,0.24,0.003,0.367-0.346,1.375l0.259-0.057c-0.087,2.418-1.131,5.074-1.343,7.707l-0.265,0.034l0.184,0.563
            c-0.295,1.394-0.634,2.99-0.957,4.517c-0.371,1.514-0.725,2.958-0.994,4.059c-0.018,0.378-0.016,0.86-0.053,1.391
            c-0.059,0.524-0.169,1.095-0.373,1.651v-0.128c-0.42,2.019-0.879,4.162-1.784,6.231c-0.057-0.167-0.2,0.02-0.185-0.239
            c0.096,0.594-0.408,1.251-0.569,1.703l0.36-0.471c0.023,0.686-0.489,1.478-0.77,1.72l-0.115,1.035
            c-0.049,0.052-0.124,0.134-0.167,0.132l0.06,0.215l-0.511,0.378c-0.028,0.257-0.438,1.527-0.296,2.039
            c-0.14,0.049-0.567,0.893-0.355,0.19c0,0-0.09,0.212-0.226,0.53c-0.129,0.32-0.321,0.739-0.53,1.151
            c-0.405,0.828-0.811,1.656-0.811,1.656l0.348-0.475c0.263,0.038-0.052,1.117-0.396,1.592c0.126-0.772-0.302-0.208-0.604,0.004
            c-0.083,0.514,0.384-0.302,0.401-0.002c-0.106,0.646-0.575,1.907-0.853,2.145l0.082-0.381c-0.31,0.803-0.888,0.957-1.255,1.713
            c-0.166,0.766-0.591,1.176-0.132,1.24c-0.426,1.019-0.456,0.236-0.657,0.531c-0.032,0.567-0.911,1.834-1.211,1.888
            c0.255,0.837-1.681,2.427-1.329,2.896l-0.713,0.616c0.107,0.101,0.286,0.419-0.063,1.064c-0.508,0.493-0.631,0.221-0.87,0.315
            l0.2,0.677c-2.543,3.068-5.435,7.425-8.533,10.483c-0.59,0.765-1.258,1.631-1.95,2.528c-0.693,0.899-1.444,1.806-2.275,2.573
            l0.124-0.227l-1.67,1.504l1.313-0.995c-0.297,0.395-0.489,0.741-0.827,1.076c-0.468,0.404-0.974,0.843-1.506,1.304
            c-0.552,0.44-1.122,0.908-1.695,1.399c-1.146,0.982-2.282,2.075-3.375,3.135c-1.102,1.742-1.347,0.422-2.493,1.982
            c0.087-0.148,0.077-0.255,0.148-0.396c-1.439,1.436-2.271,2.153-3.662,3.694c-0.167-0.622-1.053,0.802-1.103,0.423l0.007,0.299
            c-0.461,0.135-1.366,0.696-1.343,0.462l-0.559,0.767l-0.208-0.167c-0.497,0.324-1.225,0.916-1.961,1.369
            c-0.741,0.444-1.455,0.801-1.856,0.746c-0.57,0.47-1.248,0.971-1.994,1.486c-0.752,0.506-1.602,0.976-2.463,1.471
            c-0.869,0.48-1.747,0.996-2.646,1.431c-0.904,0.424-1.792,0.841-2.626,1.232c-0.377,0.406-1.314,0.947-2.087,1.285l-0.948,0.085
            c-0.741,0.429-1.423,1.148-2.229,1.291c-0.01-0.034,0.011-0.07,0.055-0.109c-0.766,0.424-1.598,0.571-2.255,1.119
            c-0.896,0.328-1.818,0.51-1.646,0.141c0.091,0.506-1.903,0.656-1.903,0.656s-0.729,0.223-2.024,0.624
            c-1.296,0.399-3.149,1.007-5.459,1.568c-4.588,1.22-10.918,2.553-17.706,3.238c-6.784,0.719-13.995,0.798-20.24,0.458
            c-1.564-0.05-3.06-0.215-4.487-0.285c-1.429-0.058-2.767-0.207-4.011-0.313c-1.243-0.11-2.397-0.158-3.424-0.23
            c-1.024-0.09-1.933-0.139-2.707-0.14c-0.45,0.151-1.588,0.058-1.237,0.441c-0.874-0.104-1.155-0.62-1.381-0.415
            c1.116,0.426-0.521,0.543,0.541,1.104l-0.746-0.108c-0.193,0.138,1.044,0.032,0.786,0.304l0.318-0.405
            c0.574,0.204,0.876,0.372,1.048,0.574c0.6-0.049,1.091-0.586,2.354-0.013l-0.372-0.057c1.081,0.23,0.599,0.446,0.809,0.573
            l-0.239-0.059c0,0,0.216,0.109,0.432,0.219c0.215,0.114,0.435,0.213,0.435,0.213c-0.662-0.298-1.881-0.199-1.278-0.086
            c1.268,0.425-0.312-0.188,0.67-0.021c0.606,0.113,0.388,0.319,0.333,0.455l1.098,0.179c-0.079,0.206-0.723-0.14-0.322,0.158
            l1.424,0.018c0.162,0.586,1.546,0.005,2.091,0.626c-1.016-0.048,0.059,0.198-0.127,0.346c0,0,0.613,0.1,1.225,0.2
            c0.617,0.071,1.233,0.143,1.233,0.143c0.223,0.104-0.672,0.087-0.294,0.106c0.444,0.207,0.781-0.037,1.03-0.002
            c0.195,0.176,1.839,0.223,1.729,0.575c1.651,0.442,0.753-0.352,2.293,0.011c0.184,0.252-1.247-0.072-0.576,0.23
            c0,0,0.408,0.021,0.817,0.028c0.409,0.01,0.818,0.02,0.818,0.02c-0.157,0.096-0.348,0.333-0.956,0.24
            c2.191,0.296,3.868,0.824,5.521,1.182c1.653,0.323,3.28,0.602,5.323,0.469c0.579,0.244-0.413,0.198-0.825,0.347
            c2.96-0.392,4.19,0.513,6.734,0.36l0.032-0.22c1.781,0.185,3.746,0.165,5.746-0.056c2-0.231,4.054-0.471,6.052-0.702l-0.496-0.067
            c0.51-0.302,1.382-0.477,2.011-0.687c0.119,0.09-0.388,0.539,0.743,0.249c-0.252-0.034-0.749-0.029-0.624-0.159
            c1.752,0.027,4.389-0.472,7.292-1.065c2.88-0.689,6.021-1.499,8.745-2.313c7.226-2.566,15.027-5.645,21.491-9.594
            c-0.996,0.579-0.835-0.125-0.02-0.44c2.494-0.45,3.422-2.27,5.783-3.072c3.46-2.709,7.122-4.417,9.813-7.609l0.225,0.146
            c0.543-0.306,0.555-0.549,1.028-0.983l0.035,0.064c1.244-0.886,2.41-2.337,3.126-2.844l-0.155-0.25
            c0.642-0.631,0.495-0.155,1.057-0.904l0.08,0.123l0.598-0.932c0.302,0.46,1.828-1.763,2.488-2.121
            c0.756-0.895,2.143-2.619,3.358-3.899c0.402-0.081,1.869-1.683,2.842-2.674c0.823-1.068,1.822-2.365,2.878-3.734
            c1.018-1.398,2.073-2.881,3.119-4.242l-0.306-0.138c0,0,0.126-0.184,0.253-0.368c0.128-0.183,0.24-0.376,0.24-0.376
            c0.052,0.051,0.149-0.028,0.104,0.104c0.688-0.372,1.031-1.793,1.616-2.269c-0.496,0.217-0.381,0.5-0.666,0.924
            c-0.53,0.527-0.412-0.234,0.262-0.973l0.05,0.056l0.224-0.659c0.257,0.076,0.691-0.375,0.949-0.306
            c0.804-1.238,1.276-2.402,1.828-3.526c0.52-1.143,1.148-2.232,1.871-3.566c0.108,0.412,0.939-1.972,1.099-1.2
            c0.673-1.71-0.495,0.082-0.424-0.818c0.241-0.447,0.363-0.981,0.569-0.98c-0.003,0.266,0.46-0.232,0.311,0.155l0.525-1.1
            c0.087-0.087,0.026,0.182-0.006,0.316c0.792-2.368,2.156-3.861,2.771-6.026c-0.102-0.223-0.374,0.801-0.485,0.577
            c0.503-2.212,1.032-1.715,1.565-3.753l0.071,0.491c0.091-0.69-0.001-1.038,0.593-2c0.348-0.079-0.122,0.96-0.192,1.508
            c0.146-0.354,0.38-0.987,0.518-1.475c0.125-0.492,0.146-0.842-0.071-0.607c0.204-0.897,0.556-1.124,0.789-1.567
            c-0.063-0.038-0.458,0.745-0.414,0.193c0.539-1.127,1.2-2.937,1.641-4.391c0.064,0.033,0.198-0.037,0.196,0.101
            C268.929,184.891,269.517,185.2,269.897,183.556z"/>
       </g>
       <g>
           <path fill="#939598" d="M261.817,104.76l0.229,0.566c0.058-0.027,0.141-0.035,0.324,0.19L261.817,104.76z"/>
       </g>
       <g>
           <path fill="#939598" d="M270.519,132.169c0.036,0.07,0.069,0.14,0.114,0.2C270.596,132.227,270.556,132.185,270.519,132.169z"/>
       </g>
       <g>
           <path fill="#939598" d="M272.092,139.023c-0.124-0.491-0.146-0.037-0.19,0.216C271.961,139.244,272.029,139.224,272.092,139.023z"
                 />
       </g>
       <g>
           <path fill="#939598" d="M253.15,90.962c0.082,0.072,0.15,0.104,0.191,0.055C253.219,90.929,253.168,90.926,253.15,90.962z"/>
       </g>
       <g>
           <path fill="#939598" d="M221.74,59.274c0.04,0.043,0.076,0.082,0.125,0.136C221.85,59.374,221.805,59.327,221.74,59.274z"/>
       </g>
       <g>
           <path fill="#939598" d="M223.554,61.03c0.101,0.068,0.237,0.148,0.444,0.246C223.823,61.141,223.679,61.072,223.554,61.03z"/>
       </g>
       <g>
           <path fill="#939598" d="M258.811,100.251c0.09,0.142,0.188,0.274,0.31,0.37C258.99,100.399,258.892,100.306,258.811,100.251z"/>
       </g>
       <g>
           <path fill="#939598" d="M224.451,61.528l0.485,0.396c0.014-0.013,0.027-0.021,0.041-0.04L224.451,61.528z"/>
       </g>
       <g>
           <path fill="#939598" d="M221.103,58.807c0.198,0.144,0.476,0.328,0.638,0.468C221.523,59.035,221.319,58.896,221.103,58.807z"/>
       </g>
       <g>
           <path fill="#939598" d="M242.797,78.2c0.015,0.013,0.031,0.028,0.05,0.044C243.269,78.52,243.023,78.33,242.797,78.2z"/>
       </g>
       <g>
           <path fill="#939598" d="M226.949,62.754c0.019,0.018,0.041,0.04,0.062,0.059l0.195,0.045L226.949,62.754z"/>
       </g>
       <g>
           <path fill="#939598" d="M218.876,57.251c0.105,0.055,0.206,0.101,0.298,0.116c-0.079-0.058-0.172-0.125-0.289-0.211
                                   C218.895,57.206,218.889,57.232,218.876,57.251z"/>
       </g>
       <g>
           <path fill="#939598" d="M236.578,71.161c-0.206-0.04-0.306-0.031-0.348,0.006C236.387,71.303,236.52,71.356,236.578,71.161z"/>
       </g>
   </g>

</svg>
)

export default Logo


