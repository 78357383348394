import React from "react";
import { Link } from "gatsby";
import MenuNav from '../components/MenuNav'
import Logo from '../img/Logo'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }  

  render() {

    // // toggle the active boolean in the state
    // document.querySelector('.nav').addEventListener('transitionend', function(e){
    //   console.log('1');
    //   if(e.srcElement.style.display === "flex"){
    //     console.log('2');
    //     e.srcElement.style.display = "none";
    //   }      
    // });

    return (
      <header className={`header ${this.state.navBarActiveClass}`}>
        <div className="container header__content">
          <Link className="header__logo" activeClassName="active" to="/">
            <Logo />
          </Link>
          <button 
            className={`nav__burger hamburger`}
            onClick={() => this.toggleHamburger()}
          >
            <span className="hamburger-box ">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div> 

        <nav className="nav">
          <div className="container">
            <ul className="nav__list" role="navigation"
              aria-label="main-navigation">
              <li>
                <Link activeClassName="active" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/menus">
                  All Menus
                </Link>
                <MenuNav />
              </li>
              <li>
                <Link activeClassName="active" to="/about">
                  About
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>   
          </div>

        </nav>        
      </header>      
    );
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        if(this.state.active){
          this.setState({
              navBarActiveClass: "nav__active"
          });
        } else{
          this.setState({
            navBarActiveClass: ""
          });
        }
      }
    );
  };
};

export default Navbar;

if(typeof window !== 'undefined'){

  window.addEventListener('scroll', function(){
    let doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    if(top > 100){
      document.body.classList.add('sticky');
    } else{
      document.body.classList.remove('sticky');
    }
  });
}


  

